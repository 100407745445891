import { cn, isString } from '@utils';
import Flag from '@elements/Flag';

const defaultProps = {
  flagClassNames: '',
  displayTooltip: true,
};

export default function Flags(props) {
  const {
    flagClassNames,
    displayTooltip,
  } = { ...defaultProps, ...props };

  if (!props.flags) return null;

  const flags = isString(props.flags) ? JSON.parse(props.flags) : props.flags;

  if (!flags || !flags.length) return null;

  return (
    <span className={cn('tw-flex tw-gap-1', props.classNames)} style={props.style}>
      {flags.map((flag, index) => (
        <Flag
          key={index}
          label={flag.label}
          icon={flag.icon}
          color={flag.color}
          classNames={flagClassNames}
          displayTooltip={displayTooltip}
        />
      ))}
    </span>
  );
}
